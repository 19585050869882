<template>
  <div v-if="!isEdiding">
    <bulma-carousel-modal :active="modalOpen"
      :closable="true"
      :start-index="selectedImage"
      :image-ids="imageIds"
      :image-height="1000"
      :image-width="1000"
      @close="close" />

    <div class="tile is-ancestor is-parent">
      <div class="tile is-parent">
        <div class="is-flex is-flex-wrap-wrap flex-full-width"
          v-if="!isLoadingImages">
          <article class="tile is-child detail-page-tile">
            <template v-for="category in imageCategories">
              <div class="tile"
                :key="category.id">
                <div class="tile is-parent">
                  <article class="tile is-child detail-page-tile">
                    <div class="columns">
                      <div class="column">
                        <div class="category-name is-size-4 has-text-weight-semibold">{{ category.name }}</div>
                        <!-- <div class="is-size-5">{{ subtitle }} <span :class="{ 'has-text-danger' : selectedCount > maxSelected }">({{ selectedCount }}/{{ imagesWithInfo.length }})</span></div> -->
                      </div>
                    </div>
                    <div v-if="isLoadingImages"
                      class="columns is-multiline dragable-box">
                      <div class="section">
                        <div class="is-loading-image-popup content has-text-grey has-text-centered" />
                      </div>
                    </div>
                    <div v-else-if="getImagesWithInfoByCategory(category.quoteImageCategoryId).length === 0"
                      class="content has-text-grey is-multiline dragable-box">
                      <div class="column is-one-fifth has-text-centered">
                        <span icon="icon is-large">
                          <i class="mdi mdi-48px mdi-emoticon-sad" />
                        </span>
                        <div>No Images</div>
                      </div>
                    </div>
                    <div v-else
                      class="columns is-flex is-multiline dragable-box">
                      <div v-for="(image, index) in getImagesWithInfoByCategory(category.quoteImageCategoryId)"
                        :key="image.quoteImageId"
                        class="p-2">
                        <div v-if="image.imageContent"
                          :class="'card'"
                          style="box-shadow: 0 0 0 1px #d9d9d9">

                          <header class="card-header">

                            <div class="card-header-title p-1 has-background-grey-lighter is-flex is-justify-content-end">
                              <a class="has-text-primary is-outlined"
                                @click="editImage(image.quoteImageId)">
                                <span class="icon">
                                  <i class="mdi mdi-lead-pencil mdi-24px"
                                    aria-hidden="true" />
                                </span>
                              </a>
                            </div>

                          </header>

                          <div class="card-image">
                            <a @click="viewImage(index)">
                              <figure class="image is-128x128">
                                <div style="position: relative">
                                  <i v-if="image.isPrimary"
                                    class="mdi mdi-star mdi-24px has-text-gold"
                                    style="position: absolute; z-index: 1" />
                                  <img v-if="image.imageContent"
                                    class="image is-128x128"
                                    style="object-fit: cover;"
                                    :src="image.imageContent"
                                    :key="image.quoteImageId"
                                    :id="image.quoteImageId">
                                </div>
                              </figure>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </template>
          </article>
        </div>
      </div>
    </div>

    <!-- <div v-for="(image, index) in imagesWithInfo"
        :key="image.quoteImageId"
        class="p-2">
        <div v-if="image.imageContent"
          :class="'card'"
          style="box-shadow: 0 0 0 1px #d9d9d9">

          <header class="card-header">

            <div class="card-header-title p-1 has-background-grey-lighter is-flex is-justify-content-end">
              <a class="has-text-primary is-outlined"
                @click="editImage(image.quoteImageId)">
                <span class="icon">
                  <i class="mdi mdi-lead-pencil mdi-24px"
                    aria-hidden="true" />
                </span>
              </a>
            </div>

          </header>

          <div class="card-image">
            <a @click="viewImage(index)">
              <figure class="image is-128x128">
                <div style="position: relative">
                  <i v-if="image.isPrimary"
                    class="mdi mdi-star mdi-24px has-text-gold"
                    style="position: absolute; z-index: 1" />
                  <img v-if="image.imageContent"
                    class="image is-128x128"
                    style="object-fit: cover;"
                    :src="image.imageContent"
                    :key="image.quoteImageId"
                    :id="image.quoteImageId">
                </div>
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div> -->

    <div v-show=" isLoadingImages"
      class="columns is-multiline">
      <div class="section">
        <span class="is-loading-image has-text-black has-text-centered" />
      </div>
    </div>

  </div>
</template>

<script>
import QuoteService from './QuoteService'
import BulmaCarouselModal from '../../components/BulmaCarousel/BulmaCarouselModal.vue'
import { OpenImage } from '@/components/VuePainterro'

const MaximumImages = 99

export default {
  name: 'QuoteImages',
  components: {
    BulmaCarouselModal
  },
  props: {
    value: null,
    quoteIds: {
      type: Array,
      default: () => {
        return []
      }
    },
    maxSelected: {
      type: Number,
      default: MaximumImages
    },
    subtitle: {
      type: String,
      default: 'Select images to attach'
    },
    isAudanet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      size: 200,
      isLoadingImages: true,
      images: [],
      imageIds: [],
      selectedImage: null,
      modalOpen: false,
      imagesWithInfo: [],
      isEdiding: false,
      imageCategories: null,
      quoteId: ''
    }
  },
  watch: {
    value(value) {
      this.quoteId = value.quoteId
    }
  },
  async created() {
    document.title = 'Images for Quote ' + this.value.quoteNo
    await this.getImageCategories()
    this.getThumbnails()
  },
  methods: {
    async getThumbnail(id, index) {
      var thumbnail = await QuoteService.getImageThumbnail(id, this.size)
      this.images.splice(index, 1, thumbnail)
    },
    async getThumbnailWithInfo(id, index) {
      var thumbnail = await QuoteService.getImageThumbnailWithInfo(id, this.size)
      return thumbnail
    },
    async getThumbnails() {
      this.isLoadingImages = true
      this.imagesWithInfo.splice(0, this.imagesWithInfo.length)
      const vm = this
      this.imageIds = await QuoteService.getImageIds(this.value.quoteId)
      const promises = this.imageIds.map(function (id, index) {
        return vm.getThumbnailWithInfo(id, index)
      })
      this.imagesWithInfo = await Promise.all(promises)
      this.isLoadingImages = false
      //this.$eventHub.$emit(EventHubTypes.ImageCountChanged, this.imageIds.length)
    },
    viewImage(index) {
      this.modalOpen = true
      this.selectedImage = index
    },
    close() {
      this.modalOpen = false
      this.selectedImage = null
    },
    async closed() {
      window.scroll(this.lastScrollX, this.lastScrollY)
      this.isEdiding = false
      //await this.getThumbnails()
    },
    editImage(id) {
      this.$showSpinner()
      this.lastScrollX = window.scrollX
      this.lastScrollY = window.scrollY
      window.scrollTo(0, 0)
      OpenImage(id, this.saved, this.closed, false)
      this.$hideSpinner()
      this.isEdiding = true
    },
    async saved(id) {
      // Callback function to update thumbnail after saving images in Painterro
      const thumbnail = this.imagesWithInfo.find((i) => i.quoteImageId === id)
      const updatedThumbnail = await this.getThumbnailWithInfo(id, this.size)
      thumbnail.imageContent = updatedThumbnail.imageContent
    },
    async getImageCategories() {
      this.imageCategories = await QuoteService.getQuoteImageCategories()
    },
    getImagesWithInfoByCategory(categoryId) {
      const images = this.imagesWithInfo
      const category = this.imageCategories.find(i => i.quoteImageCategoryId === categoryId)
      const noCategory = (category.orderIndex === 0) ? images.filter(i => !i.imageCategoryId) : []
      const filtered = images.filter(i => i.imageCategoryId === categoryId)
      const combined = filtered.concat(noCategory).sort((a, b) => a.orderIndex - b.orderIndex)
      return combined
      //return this.imagesWithInfo.filter((i) => i.imageCategoryId == categoryId)
    }
  }
}
</script>

<style scoped>
.is-loading-image {
  left: 45%;
}

.is-multiline.dragable-box {
  min-height: 3em;
  border: dashed 2px #e3e2e2;
  border-radius: 2px;
  margin-left: 2px;
  margin-right: 3px;
  background-color: #fafafa;
}

.flex-full-width {
  flex: 0 0 100%;
}
</style>